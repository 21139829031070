
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DicInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'
import { oneDecimal, twoDecimal } from '@/utils/validate'

@Component
export default class Dictionary extends Vue {
  @Prop({ default: '' }) libType!: string
  @Watch('libType')
  initData () {
    this.page = 1
    this.total = 0
    this.size = 10
    this.getLibUnitList()
    this.getLibTypeList()
    this.getDataList()
  }

  private dialogFormVisible = false
  private libTypeList: Array<DicInfo> = []
  private libUnitList: Array<DicInfo> = []
  private title = '新增'
  private info: any = this.addInit

  private rules = {
    libName: [{ required: true, message: '请输入名称', trigger: ['blur'] }],
    libUnit: [{ required: true, message: '请选择单位', trigger: ['blur'] }],
    maintainNum: [{ required: true, message: '请输入养护期', trigger: ['blur'] },
      { validator: oneDecimal, trigger: ['blur', 'change'] }]
  }

  private rule = { validator: twoDecimal, trigger: ['blur', 'change'] }

  private submitShow = false
  private page = 1
  private size = 10
  private total = 0
  private loading = false

  private childList: Array<DicInfo> = []
  private list: Array<object> = []

  get addInit () {
    let info = {}
    this.libTypeList && this.libTypeList.forEach(item => {
      info = { ...info, [item.fieldKey]: '0' }
    })
    return {
      libName: '',
      ...info,
      libUnit: '',
      maintainNum: '12',
      totalPrice: 0
    }
  }

  created () {
    this.getLibUnitList()
    this.getLibTypeList()
    this.getDataList()
  }

  // 单位列表
  getLibUnitList () {
    this.$axios.get(this.$apis.businessData.selectDicByPage, {
      dicType: 'libUnit'
    }).then((res) => {
      this.libUnitList = res.list || []
    })
  }

  // 定额库字段列表
  getLibTypeList () {
    this.$axios.get(this.$apis.preBidCalc.selectCalculateLibraryFieldByList, {
      libType: this.libType
    }).then((res) => {
      this.libTypeList = res.list || []
    })
  }

  // 数据项列表
  getDataList () {
    this.loading = true
    this.$axios.get(this.$apis.preBidCalc.selectCalculateLibraryByPage, {
      libType: this.libType,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.list = res.library && res.library.list ? res.library.list : []
      this.childList = res.fieldList || []
      this.total = res.library && res.library.total ? res.library.total : 0
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.info = { ...this.addInit }
    this.title = '新增'
    this.dialogFormVisible = true
  }

  onUpdate (data: any) {
    this.info = {
      ...this.addInit,
      ...data.data,
      libId: data.libId,
      libName: data.libName,
      libType: data.libType,
      libUnit: data.libUnit,
      maintainNum: data.maintainNum,
      totalPrice: data.totalPrice
    }
    this.calcTotalPrice('0')
    this.title = '编辑'
    this.dialogFormVisible = true
  }

  onCancel () {
    this.dialogFormVisible = false
  }

  onDelete (data: { libId: string; libName: string }) {
    this.$confirm('是否确认删除【' + data.libName + '】？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      const url = this.$apis.preBidCalc.deleteCalculateLibrary
      this.$axios.post(url, {
        libId: data.libId
      }).then(() => {
        this.$message.success('操作成功')
        this.getDataList()
      })
    })
  }

  calcTotalPrice (value: string) {
    const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,2})?$/
    if (!value || reg.test(value)) {
      if (this.libTypeList.length) {
        let total = 0
        this.libTypeList.forEach(item => {
          total += Number(this.info[item.fieldKey] || 0)
        })
        this.info.totalPrice = Math.round(total * 100) / 100
      }
    }
  }

  submitForm () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.info && this.info.libId ? this.$apis.preBidCalc.updateCalculateLibrary : this.$apis.preBidCalc.insertCalculateLibrary
        this.libTypeList && this.libTypeList.forEach(item => {
          if (!this.info[item.fieldKey]) {
            this.info[item.fieldKey] = '0'
          }
        })
        this.$axios.post(url, {
          libType: this.libType,
          ...this.info
        }).then(() => {
          this.$message.success('保存成功')
          this.onCancel()
          this.getDataList()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
