
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo } from '@/types/common'
import QLSChildPerson from '@/views/preBidCalc/QLSChildPerson.vue'
import QLSChildCustom from '@/views/preBidCalc/QLSChildCustom.vue'

@Component({
  components: { QLSChildPerson, QLSChildCustom }
})
export default class Dictionary extends Vue {
  private libTypeList: Array<DicInfo> = []
  private libType = 'person'

  created () {
    this.getLibTypeList()
  }

  // 定额子母列表
  getLibTypeList () {
    this.$axios.get(this.$apis.businessData.selectDicByPage, {
      dicType: 'libType'
    }).then((res) => {
      this.libTypeList = res.list || []
    })
  }
}
