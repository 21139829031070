
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'
import { oneDecimal, twoDecimal } from '@/utils/validate'

@Component
export default class Dictionary extends Vue {
  private maintainTypeList: Array<DicInfo> = []
  private maintainGradeList: Array<DicInfo> = []

  private dialogFormVisible = false
  private title = '新增'
  private info: any = this.addInit
  private rules = {
    libName: [{ required: true, message: '请输入名称', trigger: ['blur'] }],
    maintainType: [{ required: true, message: '请选择养护类型', trigger: ['blur'] }],
    maintainGrade: [{ required: true, message: '请选择养护等级', trigger: ['blur'] }],
    productPrice: [{ required: true, message: '请输入产品定额', trigger: ['blur'] },
      { validator: twoDecimal, trigger: ['blur', 'change'] }],
    costPrice: [{ required: true, message: '请输入费用定额', trigger: ['blur'] },
      { validator: twoDecimal, trigger: ['blur', 'change'] }],
    maintainNum: [{ required: true, message: '请输入养护期', trigger: ['blur'] },
      { validator: oneDecimal, trigger: ['blur', 'change'] }]
  }

  private submitShow = false
  private page = 1
  private size = 10
  private total = 0
  private loading = false

  private list: Array<object> = []

  get addInit () {
    return {
      libName: '',
      maintainType: '',
      maintainGrade: '',
      productPrice: '',
      costPrice: '',
      maintainNum: '12',
      content: '',
      remarks: ''
    }
  }

  created () {
    this.getDataList()
    this.getMaintainTypeList()
    this.getMaintainGradeList()
  }

  // 数据项列表
  getDataList () {
    this.loading = true
    this.$axios.get(this.$apis.preBidCalc.selectCalculateLibraryUserByPage, {
      page: this.page,
      size: this.size
    }).then((res) => {
      this.list = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  // 养护类型列表
  getMaintainTypeList () {
    this.$axios.get(this.$apis.businessData.selectDicByPage, {
      dicType: 'maintainType'
    }).then((res) => {
      this.maintainTypeList = res.list || []
    })
  }

  // 养护类型列表
  getMaintainGradeList () {
    this.$axios.get(this.$apis.businessData.selectDicByPage, {
      dicType: 'maintainGrade'
    }).then((res) => {
      this.maintainGradeList = res.list || []
    })
  }

  onAdd () {
    this.title = '新增'
    this.dialogFormVisible = true
    this.info = { ...this.addInit }
  }

  onUpdate (data: any) {
    this.title = '编辑'
    this.dialogFormVisible = true
    this.info = data
  }

  onCancel () {
    this.dialogFormVisible = false
  }

  onDelete (data: { libId: string; libName: string }) {
    this.$confirm('是否确认删除【' + data.libName + '】？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      const url = this.$apis.preBidCalc.deleteCalculateLibraryUser
      this.$axios.post(url, {
        libId: data.libId
      }).then(() => {
        this.$message.success('操作成功')
        this.getDataList()
      })
    })
  }

  submitForm () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.info && this.info.libId ? this.$apis.preBidCalc.updateCalculateLibraryUser : this.$apis.preBidCalc.insertCalculateLibraryUser
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.onCancel()
          this.getDataList()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
